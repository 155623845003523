
import './Name.css';

function Name(props) {
    return (
        <>
        <div className="Name" >
            <span className="Name">
            {props.name}
            </span>
        </div>
        <div className="Subtitle">
            <span className="Subtitle">
            {props.subtitle}
            </span>
        </div>
        </>
    )

}

export default Name;